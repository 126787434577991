<template>
  <div>
    <section class="page-title text-center">
      <div class="auto-container">
        <div class="content-box">
          <h1>اتصل بنا</h1>
          <div class="text">
            <nav class="woocommerce-breadcrumb">
              <router-link to="/">الرئيسيه</router-link>&nbsp;/&nbsp;اتصل بنا
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="page-details " style="padding-bottom: 0;">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <img class="img-fluid" src="../assets/home-contactus.png" />
          </div>
          <div class="col-md-7">
            <div class="container text-right">
              <h3>
                اتصل بنا
              </h3>
              <form method="post" @submit.prevent="addM">
                <div class="row mb-3">
                  <div class="col">
                    <input
                      type="text"
                      style="height: 54px;"
                      class="form-control text-right"
                      v-model="body.phone"
                      placeholder="الهاتف"
                    />
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      style="height: 54px;"
                      class="form-control text-right"
                      placeholder="الاسم"
                      v-model="body.name"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <input
                      type="text"
                      style="height: 54px;"
                      class="form-control text-right"
                      v-model="body.email"
                      placeholder="البريد الالكتروني"
                    />
                  </div>
                  <div class="col">
                    <input
                      type="text"
                      style="height: 54px;"
                      class="form-control text-right"
                      v-model="body.subject"
                      placeholder="عنوان الرساله"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="6"
                    v-model="body.message"
                  ></textarea>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-more">ارسال</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        phone: null,
        email: null,
        subject: null,
        message: null,
      },
    };
  },
  methods: {
    addM() {
      if (
        this.body.name &&
        this.body.phone &&
        this.body.email &&
        this.body.subject &&
        this.body.message
      ) {
        
        this.$http.post(`order/addM?name=${this.body.name}&phone=${this.body.phone}&email=${this.body.email}&message=${this.body.message}`, this.body).then(
          (res) => {
            this.body.name = null;
            this.body.phone = null;
            this.body.email = null;
            this.body.subject = null;
            this.body.message = null;

            this.$swal.fire({
              icon: 'success',
              title: 'Success',
              text: `تم ارسال رسالتك بنجاح`,
              showConfirmButton: false,
              timer: 1500,
            });
          },
          () => {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: `حدث خطأ فى الاتصال`,
              showConfirmButton: false,
              timer: 1500,
            });
          },
        );
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: `يرجى ملاء الحقول`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>
